import * as React from "react";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import "../../App";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { useNavigate, useParams } from "react-router-dom";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import Tab from "@mui/material/Tab";
import TabContext from "@mui/lab/TabContext";
import TabList from "@mui/lab/TabList";
import TabPanel from "@mui/lab/TabPanel";
import {
	Autocomplete,
	Checkbox,
	Container,
	FormControlLabel,
	Input,
	Select,
	Skeleton,
	TextField
} from "@mui/material";
import { connect, useDispatch, useSelector } from "react-redux";
import {
	delete_Model,
	delete_Model_Media,
	deleteModel,
	getModel,
	getModelMedia,
	getModelsEarnings,
	updateModel
} from "../../redux/actions/modelAction";
import Uploader from "../Upload/Upload";
import Paper from "@mui/material/Paper";
import List from "@mui/material/List";
import UploaderVideo from "../Upload/UploadVideos";
import MenuItem from "@mui/material/MenuItem";
import { useSnackbar } from "notistack";
import { removeErrors, showModal } from "../../redux/reducers/rootReducer";
import LushModal from "./LushModal";
import useVaporUpload from "../Upload/VaporUpload";

const rooms = [
	{ id: "1", title: "room1" },
	{ id: "2", title: "room2" },
	{ id: "3", title: "room3" },
	{ id: "4", title: "room4" },
	{ id: "5", title: "room5" },
	{ id: "6", title: "room6" },
	{ id: "7", title: "room7" }
];

const statuses = [
	{ id: "1", title: "active", displayTitle: "active" },
	{ id: "2", title: "away", displayTitle: "away" },
	{ id: "3", title: "disable", displayTitle: "disabled" },
	{ id: "4", title: "previous_resident", displayTitle: "previous resident" }
];
const mapStateToProps = (state) => {
	return {
		model: state.model,
		modal: state.modal
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getModel: (id) => dispatch(getModel(id)),
		removeModel: (id) => dispatch(delete_Model(id)),
		getModelMedia: (id) => dispatch(getModelMedia(id)),
		removeModelMedia: (id) => dispatch(delete_Model_Media(id))
	};
};

function ModelProfile({
	model,
	getModel,
	removeModel,
	getModelMedia,
	removeModelMedia,
	admin,
	modal
}) {
	const dispatch = useDispatch();
	const param = useParams();
	const navigate = useNavigate();

	React.useEffect(() => {
		removeModelMedia(param.id);
		removeModel(param.id);
		getModel(param.id);
		getModelMedia(param.id);
	}, []);

	const [selectedOptions, setSelectedOptions] = React.useState([]);

	const { enqueueSnackbar } = useSnackbar();
	const errorsSubmit = useSelector((state) => state.errors);

	const handleChangeIsCouple = (event) => {
		model.performer.is_couple = event.target.checked;

		setAddFormData((prevFormData) => ({
			...prevFormData,
			["is_couple"]: event.target.checked ? 1 : 0
		}));
	};
	React.useEffect(() => {
		if (errorsSubmit && errorsSubmit.username) {
			enqueueSnackbar(errorsSubmit.username[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.email) {
			enqueueSnackbar(errorsSubmit.email[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.password) {
			enqueueSnackbar(errorsSubmit.password[0], { variant: "error" });
		}
		if (errorsSubmit && errorsSubmit.password_confirmation) {
			enqueueSnackbar(errorsSubmit.password_confirmation[0], {
				variant: "error"
			});
		}
		if (errorsSubmit && errorsSubmit.mobilePhone) {
			enqueueSnackbar(errorsSubmit.mobilePhone[0], {
				variant: "error"
			});
		}

		setTimeout(() => {
			dispatch(removeErrors());
		}, 5000);
	}, [errorsSubmit]);

	const handleAutocompleteChange = (event, value) => {
		event.preventDefault();
		var task_names = value.map(function (task, index, array) {
			return task.alpha_2;
		});
		setSelectedOptions(task_names);
		const newFormData = { ...addFormData };
		model.model_blocked_countries = task_names;
		newFormData["model_blocked_countries"] = task_names;
		setAddFormData(newFormData);
	};
	const [addFormData, setAddFormData] = React.useState({
		inHouseName: "",
		age: "",
		languages: "",
		favouriteSexPosition: "",
		analThoughts: "",
		oralThoughts: "",
		fantasies: "",
		groupSexThoughts: "",
		email: "",
		mobilePhone: "",
		pageTitle: "",
		metaDescription: "",
		about_me: "",
		commissionPercent: "",
		bestTimeForSex: "",
		sizeMatter: "",
		status: "",
		isOnline: 0,
		isActive: 0,
		is_couple: 0,
		instagram: "",
		onlyfans: "",
		twitter: ""
	});

	React.useEffect(() => {
		setAddFormData({
			inHouseName: model.user && model.user.inHouseName,
			age: model.performer && model.performer.age,
			languages: model.performer && model.performer.languages,
			favouriteSexPosition:
				model.performer && model.performer.favouriteSexPosition,
			analThoughts: model.performer && model.performer.analThoughts,
			oralThoughts: model.performer && model.performer.oralThoughts,
			fantasies: model.performer && model.performer.fantasies,
			groupSexThoughts: model.performer && model.performer.groupSexThoughts,
			email: model.user && model.user.email,
			mobilePhone: model.user && model.user.mobilePhone,
			commissionPercent: model.performer && model.performer.commissionPercent,
			sizeMatter: model.performer && model.performer.sizeMatter,
			bestTimeForSex: model.performer && model.performer.bestTimeForSex,
			model_blocked_countries: model && model.model_blocked_countries,
			pageTitle: model.performer && model.performer.pageTitle,
			metaDescription: model.performer && model.performer.metaDescription,
			about_me: model.performer && model.performer.about_me,
			status: model.user && model.user.status,
			accountStatus: model.user && model.user.accountStatus,
			onlineStatus: model.user && model.user.onlineStatus,
			instagram: model.performer && model.performer.instagram,
			onlyfans: model.performer && model.performer.onlyfans,
			twitter: model.performer && model.performer.twitter,
			is_couple: model.performer && model.performer.is_couple
		});
	}, [model]);

	const handleAddForm = (event) => {
		event.preventDefault();
		const fieldName = event.target.getAttribute("name");
		const fieldValue = event.target.value;
		const newFormData = { ...addFormData };
		newFormData[fieldName] = fieldValue;
		setAddFormData(newFormData);
	};
	String.prototype.isNumber = function () {
		return /^\d+\.\d+$/.test(this);
	};
	const handleAddFormSubmit = (event) => {
		event.preventDefault();
		if (addFormData.password !== addFormData.password_confirmation) {
			alert("passwords not the same");
			return false;
		}

		/*if (
			!addFormData?.commissionPercent?.isNumber() &&
			admin &&
			admin.user.role == "superadmin"
		) {
			alert("commissionPercent must be number");
			return false;
		} else {*/
		dispatch(updateModel(addFormData, param.id));
		// navigate("/girlManagement");
		setImageUrl(null);
		return true;
		/*}*/
	};
	const handleDeleteSubmit = (event) => {
		event.preventDefault();
		dispatch(deleteModel(param.id));
		// navigate("/girlManagement");
		dispatch(getModelsEarnings());
	};

	const [value, setValue] = React.useState("1");

	const handleChange = (event, newValue) => {
		setValue(newValue);
		getModelMedia(param.id);
	};

	const changeStatus = (event, value) => {
		const newFormData = { ...addFormData };
		newFormData["accountStatus"] = value.title;
		model.user.accountStatus = value.title;
		setAddFormData(newFormData);
	};

	const [checked, setChecked] = React.useState("");
	const handleSwitchChange = (event, value) => {
		model.user.onlineStatus = value;
		setChecked(value);

		const newFormData = { ...addFormData };
		if (value == false) {
			newFormData["onlineStatus"] = false;
		} else {
			newFormData["onlineStatus"] = true;
		}

		setAddFormData(newFormData);
	};

	const getDefaultCountries = () => {
		if (!model.countries || !model.model_blocked_countries) {
			return [];
		}
		let defaultCountries = [];
		model.countries.map((country) =>
			Object.values(model.model_blocked_countries).map((blocked_country) => {
				if (country.alpha_2 == blocked_country) {
					defaultCountries.push(country);
				}
			})
		);
		return defaultCountries;
	};
	React.useEffect(() => {
		setImageUrl(model.user && model.user.smallAvatar);
	}, [model.user]);

	const [selectedImage, setSelectedImage] = React.useState(null);
	const [imageUrl, setImageUrl] = React.useState(
		model.user && model.user.smallAvatar
	);
	const { uploadFile, uploadProgress } = useVaporUpload();
	React.useEffect(() => {
		if (selectedImage) {
			const updateImage = async () => {
				setImageUrl(URL.createObjectURL(selectedImage));
				const newFormData = { ...addFormData };
				const { vaporResponse } = await uploadFile(selectedImage);
				newFormData["myFiles"] = vaporResponse.key;

				setAddFormData(newFormData);
			};
			updateImage();
		}
	}, [selectedImage]);

	React.useEffect(() => {
		setTransparentUrl(model.user && model.user.profileBackground);
	}, [model.user]);

	const [TransparentImage, setTransparentImage] = React.useState(null);
	const [TransparentUrl, setTransparentUrl] = React.useState(
		model.user && model.user.profileBackground
	);

	React.useEffect(() => {
		if (TransparentImage) {
			const updateImage = async () => {
				setTransparentUrl(URL.createObjectURL(TransparentImage));
				const newFormData = { ...addFormData };
				const { vaporResponse } = await uploadFile(TransparentImage);
				newFormData["myFilesTransparent"] = vaporResponse.key;
				setAddFormData(newFormData);
			};
			updateImage();
		}
	}, [TransparentImage]);

	function returnToModels(event) {
		event.preventDefault();
		navigate("/girlManagement");
	}

	const [time, setTime] = React.useState("");
	const handleChangeLush = (event) => {
		setTime(event.target.value);
		const newFormData = { ...addFormData };
		newFormData["lush_inactive_hours"] = event.target.value;
		setAddFormData(newFormData);
	};
	const loading = useSelector((state) => state.loading);

	const handleOpenPairLush = () => {
		// eslint-disable-next-line no-lone-blocks
		dispatch(showModal("lushPairing"));
	};
	return (
		<>
			<Box
				component="div"
				className="page-container"
				sx={{
					flexGrow: 1,
					maxWidth: "1100px",
					margin: "0 auto",
					height: "100%",
					minHeight: "700px",
					marginLeft: "290px"
				}}
			>
				<Grid container spacing={0}>
					<Grid item xs={4} md={2} spacing={0} className="LiveShow">
						{!model.user ? (
							<Skeleton width={330} height={55} />
						) : (
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								{model.user && model.user.username}'Profile
							</Typography>
						)}
					</Grid>
				</Grid>
				<Button
					startIcon={<ArrowBackIosIcon />}
					className="ModelProfilePage_arrow"
					variant="outlined"
					onClick={returnToModels}
				>
					All models
				</Button>
				<Container
					maxWidth="xl"
					style={{
						color: "#ffffff",
						width: "100%",
						height: "100%",
						backgroundColor: "#000000",
						position: "relative"
					}}
					sx={{ my: 3 }}
					className="ProvileContainer"
				>
					{loading && loading.isLoading == true ? (
						<div className="loader-container">
							<div className="spinner"></div>
						</div>
					) : (
						""
					)}
					<Grid spacing={1} className="ModelProfilePage_wrap">
						<Grid item xs={6} md={4}>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
									style={{ flexGrow: "0.6" }}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Profile image
									</Typography>
									<Box
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											width: "100%"
										}}
									>
										<Box
											mt={2}
											textAlign="center"
											style={{
												width: "100px",
												height: "100px",
												backgroundColor: "rgb(41, 41, 41)",
												borderRadius: "50%"
											}}
										>
											{imageUrl && (
												<img
													src={imageUrl}
													height="100px"
													width="100px"
													accept="image/x-png,image/gif,image/jpeg"
													style={{ borderRadius: "50%" }}
												/>
											)}
										</Box>

										<input
											accept="image/*"
											style={{ display: "none" }}
											id="select-image"
											multiple
											type="file"
											name="myFiles"
											onChange={(e) => {
												setSelectedImage(e.target.files[0]);
											}}
										/>
										<label htmlFor="select-image" style={{ marginTop: "10px" }}>
											<Button variant="raised" component="span">
												Upload
											</Button>
										</label>
									</Box>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
									style={{ flexGrow: "0.6" }}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Transparent Profile image
									</Typography>
									<Box
										style={{
											display: "flex",
											flexDirection: "column",
											alignItems: "center",
											width: "100%"
										}}
									>
										<Box
											mt={2}
											textAlign="center"
											style={{
												width: "100px",
												height: "100px",
												backgroundColor: "rgb(41, 41, 41)",
												borderRadius: "50%"
											}}
										>
											{TransparentUrl && (
												<img
													src={TransparentUrl}
													height="100px"
													width="100px"
													accept="image/x-png,image/gif,image/jpeg"
													style={{ borderRadius: "50%" }}
												/>
											)}
										</Box>

										<input
											accept="image/*"
											style={{ display: "none" }}
											id="select-image-transparent"
											multiple
											type="file"
											name="myFilesTransparent"
											onChange={(e) => {
												setTransparentImage(e.target.files[0]);
											}}
										/>
										<label
											htmlFor="select-image-transparent"
											style={{ marginTop: "10px" }}
										>
											<Button variant="raised" component="span">
												Upload
											</Button>
										</label>
									</Box>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Display Name
									</Typography>
									<Input
										className="inputBoxProfile"
										name="inHouseName"
										type="text"
										defaultValue={model.user && model.user.inHouseName}
										key={model.user && model.user.inHouseName}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>

								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Is couple
									</Typography>

									<FormControlLabel
										control={
											<Checkbox
												checked={
													model?.performer && model?.performer?.is_couple
														? 1
														: 0
												}
												name="is_couple"
												color="primary"
												onChange={handleChangeIsCouple}
											/>
										}
									/>
								</Stack>

								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Age
									</Typography>
									<Input
										className="inputBoxProfile"
										name="age"
										defaultValue={model.performer && model.performer.age}
										key={model.performer && model.performer.age}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>

								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Paid phone number
									</Typography>
									<Input
										className="inputBoxProfile"
										name="paid_phone_number"
										defaultValue={
											model.performer && model.performer.paid_phone_number
										}
										key={model.performer && model.performer.paid_phone_number}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>

								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Language
									</Typography>
									<Input
										className="inputBoxProfile"
										name="languages"
										defaultValue={model.performer && model.performer.languages}
										key={model.performer && model.performer.languages}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>

								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Favourite Sex Pos?
									</Typography>
									<Input
										className="inputBoxProfile"
										name="favouriteSexPosition"
										defaultValue={
											model.performer && model.performer.favouriteSexPosition
										}
										key={
											model.performer && model.performer.favouriteSexPosition
										}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										sx={{ fontSze: "10px" }}
										className="ModelProfilePage_elements"
									>
										Best time for Sex?
									</Typography>
									<Input
										name="bestTimeForSex"
										className="inputBoxProfile"
										defaultValue={
											model.performer && model.performer.bestTimeForSex
										}
										key={model.performer && model.performer.bestTimeForSex}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Does size matter?
									</Typography>
									<Input
										name="sizeMatter"
										className="inputBoxProfile"
										defaultValue={model.performer && model.performer.sizeMatter}
										key={model.performer && model.performer.sizeMatter}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Group sex?
									</Typography>
									<Input
										name="groupSexThoughts"
										className="inputBoxProfile"
										defaultValue={
											model.performer && model.performer.groupSexThoughts
										}
										key={model.performer && model.performer.groupSexThoughts}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Anal?
									</Typography>
									<Input
										className="inputBoxProfile"
										name="analThoughts"
										defaultValue={
											model.performer && model.performer.analThoughts
										}
										key={model.performer && model.performer.analThoughts}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Oral?
									</Typography>
									<Input
										className="inputBoxProfile"
										name="oralThoughts"
										defaultValue={
											model.performer && model.performer.oralThoughts
										}
										key={model.performer && model.performer.oralThoughts}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Page title
									</Typography>
									<Input
										name="pageTitle"
										className="inputBoxProfile"
										defaultValue={model.performer && model.performer.pageTitle}
										key={model.performer && model.performer.pageTitle}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Meta description
									</Typography>
									<Input
										name="metaDescription"
										className="inputBoxProfile"
										defaultValue={
											model.performer && model.performer.metaDescription
										}
										key={model.performer && model.performer.metaDescription}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
							</Box>
						</Grid>
						<Grid
							item
							xs={6}
							md={8}
							style={{ margin: "0 auto", textAlign: "center" }}
						>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Fantasy?
									</Typography>
									<Input
										className="inputBoxProfile"
										name="fantasies"
										defaultValue={model.performer && model.performer.fantasies}
										key={model.performer && model.performer.fantasies}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Bio Text
									</Typography>
									<Input
										name="about_me"
										className="inputBoxProfile"
										defaultValue={model.performer && model.performer.about_me}
										key={model.performer && model.performer.about_me}
										disableUnderline="true"
										style={{
											backgroundColor: "#292929",
											minHeight: "100px",
											maxHeight: "200px",
											minWidth: "300px",
											overflow: "hidden",
											padding: "5px 8px"
										}}
										multiline="true"
										rows={5}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Status text
									</Typography>
									<Input
										className="inputBoxProfile"
										name="status"
										disableUnderline="true"
										defaultValue={model.user && model.user.status}
										key={model.user && model.user.status}
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Account status
									</Typography>

									<Autocomplete
										className="ScheduleInputStatus"
										disablePortal
										name="accountStatus"
										id="combo-box-demo"
										options={statuses}
										disableClearable
										onChange={changeStatus}
										defaultValue={
											model.user &&
											statuses.find((elem) => {
												return model.user.accountStatus == elem.title;
											})
										}
										/* value={liveshow.performers ? liveshow.performers : []} */
										key={
											model.user &&
											statuses.find((elem) => {
												return model.user.accountStatus == elem.title;
											})
										}
										getOptionLabel={(option) =>
											option.displayTitle.charAt(0).toUpperCase() +
											option.displayTitle.slice(1)
										}
										renderInput={(params) => (
											<TextField
												{...params}
												name="accountStatus"
												disabled
												variant="standard"
											/>
										)}
									/>
								</Stack>
								{/* <Stack
                  direction="row"
                  className="ModelProfilePage_row"
                  spacing={1}
                >
                  <Typography
                    gutterBottom
                    component="div"
                    className="ModelProfilePage_elements"
                  ></Typography>
                  <Switch
                    name="isOnline"
                    checked={
                      model.user && model.user.onlineStatus == true
                        ? true
                        : false
                    }
                    className="switch"
                    onChange={handleSwitchChange}
                  />{" "}
                  <Typography>Online</Typography>
                </Stack> */}
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Mobile Number
									</Typography>
									<Input
										name="mobilePhone"
										className="inputBoxProfile"
										disableUnderline="true"
										defaultValue={model.user && model.user.mobilePhone}
										key={model.user && model.user.mobilePhone}
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								{admin && admin.user.role == "superadmin" && (
									<Stack
										direction="row"
										className="ModelProfilePage_row"
										spacing={1}
									>
										<Typography
											gutterBottom
											component="div"
											className="ModelProfilePage_elements"
										>
											Commission %
										</Typography>
										<Input
											name="commissionPercent"
											className="inputBoxProfile"
											defaultValue={
												model.performer && model.performer.commissionPercent
											}
											key={model.performer && model.performer.commissionPercent}
											disableUnderline="true"
											style={{ backgroundColor: "#292929" }}
											onChange={handleAddForm}
										></Input>
									</Stack>
								)}
								{/* <Stack
                  direction="row"
                  className="ModelProfilePage_row"
                  spacing={1}
                >
                  <Typography
                    gutterBottom
                    component="div"
                    className="ModelProfilePage_elements"
                  >
                    Assign to room
                  </Typography>
                  <Autocomplete
                    className="ScheduleInput"
                    disablePortal
                    id="combo-box-demo"
                    options={rooms}
                    getOptionLabel={(option) => option.title}
                    renderInput={(params) => (
                      <TextField {...params} disabled variant="standard" />
                    )}
                  />
                </Stack> */}
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Blocked in regions
									</Typography>
									<Autocomplete
										className="ScheduleInputStatus"
										multiple
										disablePortal
										name="model_blocked_countries"
										onChange={handleAutocompleteChange}
										id="tags-standard"
										/* model.user&& model.user.mobilePhone */
										/* model.countries ? model.countries.find((ele) => ele.alpha_2 === model.performer.model_blocked_countries[0]): [] */
										defaultValue={getDefaultCountries()}
										key={getDefaultCountries()}
										options={model.countries ? model.countries : []}
										getOptionLabel={(option) => option.name || ""}
										renderInput={(params) => (
											<TextField
												{...params}
												InputProps={{
													...params.InputProps,
													disableUnderline: true
												}}
												variant="standard"
												name="model_blocked_countries"
											/>
										)}
									/>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Email
									</Typography>
									<Input
										className="inputBoxProfile"
										disableUnderline="true"
										name="email"
										defaultValue={model.user && model.user.email}
										key={model.user && model.user.email}
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Password
									</Typography>
									<Input
										className="inputBoxProfile"
										disableUnderline="true"
										name="password"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Password confirmation
									</Typography>
									<Input
										className="inputBoxProfile"
										disableUnderline="true"
										name="password_confirmation"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Hide Lush Icon
									</Typography>
									<Select
										value={time}
										MenuProps={{
											PaperProps: {
												sx: {
													backgroundColor: "#1a1a1a",
													backgroundImage: "unset",
													".MuiMenuItem-root": {
														padding: "8px 15px",
														minHeight: "unset"
													}
												}
											}
										}}
										sx={{
											color: "white",
											fontSize: "16px",
											padding: "0",
											width: "100%",
											minWidth: "250px",
											height: "41px",
											backgroundColor: "#1a1a1a",
											fieldset: {
												border: "0"
											},
											".MuiSelect-select": {
												textAlign: "initial"
											}
										}}
										onChange={handleChangeLush}
									>
										<MenuItem value={0}>Enable lush</MenuItem>
										<MenuItem value={4}>4 hours</MenuItem>
										<MenuItem value={8}>8 hours</MenuItem>
										<MenuItem value={12}>12 hours</MenuItem>
									</Select>
								</Stack>
								{model.user && model.user.lush_disabled_by_admin && (
									<Stack
										direction="row"
										className="ModelProfilePage_row"
										spacing={1}
									>
										<Typography
											gutterBottom
											component="div"
											className="ModelProfilePage_elements"
										></Typography>
										<Typography style={{ width: "250px", color: "red" }}>
											lush was disabled by admin
										</Typography>
									</Stack>
								)}
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Model Promocode
									</Typography>
									<Input
										className="inputBoxProfile"
										disableUnderline="true"
										name="promocode"
										onChange={handleAddForm}
										disabled={
											!admin?.available_permissions?.includes(
												"panel_change_model_promocode"
											)
										}
										defaultValue={model.performer && model.performer.promocode}
										key={model.performer && model.performer.promocode}
										style={{ backgroundColor: "#292929" }}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Toys
									</Typography>
									<Button
										onClick={handleOpenPairLush}
										size="small"
										variant="contained"
										style={{
											color: "white",
											backgroundColor: "#ed2779",
											// maxWidth: "100px",
											// maxHeight: "33px",
											// minWidth: "100px",
											// minHeight: "33px",
											borderRadius: "4px"
										}}
										className="create"
									>
										Update qr codes
									</Button>
								</Stack>
							</Box>
						</Grid>
					</Grid>

					<Grid container spacing={1} className="ModelProfilePage_wrap">
						<Grid item xs={6}>
							<Box component="div" className="ModelProfilePage_main">
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Instagram
									</Typography>
									<Input
										name="instagram"
										className="inputBoxProfile"
										defaultValue={model.performer && model.performer.instagram}
										key={model.performer && model.performer.instagram}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										OnlyFans
									</Typography>
									<Input
										name="onlyfans"
										className="inputBoxProfile"
										defaultValue={model.performer && model.performer.onlyfans}
										key={model.performer && model.performer.onlyfans}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
								<Stack
									direction="row"
									className="ModelProfilePage_row"
									spacing={1}
								>
									<Typography
										gutterBottom
										component="div"
										className="ModelProfilePage_elements"
									>
										Twitter
									</Typography>
									<Input
										name="twitter"
										className="inputBoxProfile"
										defaultValue={model.performer && model.performer.twitter}
										key={model.performer && model.performer.twitter}
										disableUnderline="true"
										style={{ backgroundColor: "#292929" }}
										onChange={handleAddForm}
									></Input>
								</Stack>
							</Box>
						</Grid>
						{model?.performer?.is_couple && (
							<>
								<Grid item xs={6}>
									<Box component="div" className="ModelProfilePage_main">
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
										>
											<Typography
												gutterBottom
												component="div"
												className="ModelProfilePage_elements"
											>
												Partner's Instagram
											</Typography>
											<Input
												name="partners_instagram"
												className="inputBoxProfile"
												defaultValue={
													model.performer && model.performer.partners_instagram
												}
												key={
													model.performer && model.performer.partners_instagram
												}
												disableUnderline="true"
												style={{ backgroundColor: "#292929" }}
												onChange={handleAddForm}
											></Input>
										</Stack>

										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
										>
											<Typography
												gutterBottom
												component="div"
												className="ModelProfilePage_elements"
											>
												Partner's OnlyFans
											</Typography>
											<Input
												name="partners_onlyfans"
												className="inputBoxProfile"
												defaultValue={
													model.performer && model.performer.partners_onlyfans
												}
												key={
													model.performer && model.performer.partners_onlyfans
												}
												disableUnderline="true"
												style={{ backgroundColor: "#292929" }}
												onChange={handleAddForm}
											></Input>
										</Stack>
										<Stack
											direction="row"
											className="ModelProfilePage_row"
											spacing={1}
										>
											<Typography
												gutterBottom
												component="div"
												className="ModelProfilePage_elements"
											>
												Partner's Twitter
											</Typography>
											<Input
												name="partners_twitter"
												className="inputBoxProfile"
												defaultValue={
													model.performer && model.performer.partners_twitter
												}
												key={
													model.performer && model.performer.partners_twitter
												}
												disableUnderline="true"
												style={{ backgroundColor: "#292929" }}
												onChange={handleAddForm}
											></Input>
										</Stack>
									</Box>
								</Grid>
							</>
						)}
					</Grid>

					<Grid spacing={1} className="userProfile_grid">
						<Grid
							item
							xs={6}
							style={{
								width: "100%",
								display: "flex",
								flexDirection: "row",
								justifyContent: "center"
							}}
						>
							{/* <Link to="/girlManagement"> */}
							<Button
								onClick={handleAddFormSubmit}
								size="small"
								variant="contained"
								style={{
									color: "black",
									backgroundColor: "#FFD800",
									maxWidth: "100px",
									maxHeight: "33px",
									minWidth: "100px",
									minHeight: "33px",
									borderRadius: "4px"
								}}
								className="create"
							>
								Save
							</Button>
							{/*   </Link> */}
						</Grid>
						<Grid
							item
							xs={6}
							style={{
								display: "flex",
								flexDirection: "row",
								justifyContent: "flex-end"
							}}
						>
							<Box
								style={{
									display: "flex",
									flexDirection: "row",
									alignItems: "center",
									width: "100%",
									justifyContent: "flex-end"
								}}
							>
								<Button
									onClick={handleDeleteSubmit}
									style={{
										color: "#ED2779",
										width: "140px",
										textTransform: "unset"
									}}
									type="button"
								>
									Delete this model
								</Button>
							</Box>
						</Grid>
					</Grid>
				</Container>
				<Box>
					<Grid item xs={4} md={2} spacing={0}>
						<Typography
							variant="h5"
							gutterBottom
							component="div"
							style={{ color: "#FFFFFF" }}
						>
							My media
						</Typography>
					</Grid>
					<Box sx={{ width: "100%", typography: "body1", color: "#FFFFFF" }}>
						<TabContext value={value}>
							<Box
								sx={{
									borderBottom: 1,
									borderColor: "#FFFFFF",
									color: "#FFFFFF"
								}}
							>
								<TabList
									onChange={handleChange}
									aria-label="lab API tabs example"
								>
									<Tab style={{ color: "#FFFFFF" }} label="PHOTOS" value="1" />
									<Tab style={{ color: "#FFFFFF" }} label="VIDEOS" value="2" />
								</TabList>
							</Box>
							<TabPanel value="1">
								<Paper style={{ width: "95%", minHeight: "100px" }}>
									<List className="AllRoomBlock_list_images">
										<Uploader id={model.user && model.user.id} />
									</List>
								</Paper>
							</TabPanel>
							<TabPanel value="2">
								<Paper style={{ width: "95%", minHeight: "100px" }}>
									<List className="AllRoomBlock_list_images">
										<UploaderVideo id={model.user && model.user.id} />
									</List>
								</Paper>
							</TabPanel>
						</TabContext>
					</Box>
				</Box>
				<LushModal modal={modal} />
			</Box>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelProfile);
