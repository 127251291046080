import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import Grid from "@mui/material/Grid";
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import SearchBar from "material-ui-search-bar";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import GirlIcon from "@material-ui/icons/Face";
import { Link, useNavigate } from "react-router-dom";
import PersonAddAltIcon from "@mui/icons-material/PersonAddAlt";

import { connect, useDispatch, useSelector } from "react-redux";
import {
	getModelsEarnings,
	getModelsOrder
} from "../../redux/actions/modelAction";
import Models_Reorder_Modal from "./Models_Reorder_Modal";
import { hideModal, showModal } from "../../redux/reducers/rootReducer";

const mapStateToProps = (state) => {
	return {
		models_earnings: state.models_earnings,
		modal: state.modal
	};
};
const mapDispatchToProps = (dispatch) => {
	return {
		getModelsEarnings: () => dispatch(getModelsEarnings())
	};
};

function GirlManagement({ models_earnings, modal, getModelsEarnings }) {
	let navigate = useNavigate();
	const dispatch = useDispatch();
	const [rows, setRows] = React.useState(models_earnings);
	const [searched, setSearched] = React.useState("");

	React.useEffect(() => {
		getModelsEarnings();
	}, []);

	React.useEffect(() => {
		setRows(models_earnings);
	}, [models_earnings]);

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const requestSearch = (searchVal) => {
		const filtereredRows = models_earnings.filter((row) => {
			return row.username.toLowerCase().includes(searchVal.toLowerCase());
		});
		setRows(filtereredRows);
	};

	const cancelSearch = () => {
		setSearched("");
		requestSearch(searched);
	};

	const handleOpenPass = () => {
		dispatch(getModelsOrder());
		modal && modal.name == "Girls"
			? dispatch(hideModal("Girls"))
			: dispatch(showModal("Girls"));
	};

	const rowColor = (deadline) => {
		if (deadline === "active") {
			return "#78f73b";
		}
		if (deadline === "disable") {
			return "red";
		} else {
			return "#FFD800";
		}
	};
	const loading = useSelector((state) => state.loading);
	return (
		<>
			<Stack
				className="page-container"
				spacing={3}
				sx={{ width: 600, marginLeft: "290px", borderRadius: "10px" }}
			>
				{loading && loading.isLoading == true ? (
					<div className="loader-container">
						<div className="spinner"></div>
					</div>
				) : (
					""
				)}
				<Box
					style={{ marginTop: loading && loading.isLoading == true && "0px" }}
				>
					<Grid container spacing={0}>
						<Grid item xs={4} md={2} spacing={0} className="LiveShow">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{ color: "white" }}
							>
								Girl Management
							</Typography>
						</Grid>
					</Grid>
				</Box>
				<Box className="Search_box">
					<Typography
						variant="h6"
						gutterBottom
						component="div"
						style={{ color: "white", marginRight: "63px" }}
					>
						Find housemate
					</Typography>
					<SearchBar
						style={{ height: "35px" }}
						className="SearchBar"
						placeholder=""
						value={searched}
						onChange={(searchVal) => requestSearch(searchVal)}
						onCancelSearch={() => cancelSearch()}
					/>
				</Box>
				<Box
					style={{
						display: "flex",
						flexDirection: "row",
						justifyContent: "space-between"
					}}
				>
					<Button
						onClick={() => handleOpenPass()}
						className="Cameras_edit"
						style={{ height: "33px", padding: "0px 10px" }}
					>
						Edit Models order
					</Button>
					<Link to={"/addModelProfile"}>
						<Button
							size="small"
							variant="contained"
							style={{
								color: "black",
								backgroundColor: "#78F73B",
								maxWidth: "160px",
								maxHeight: "33px",
								minWidth: "160px",
								minHeight: "33px",
								borderRadius: "4px",
								textTransform: "unset"
							}}
							className="create"
						>
							<PersonAddAltIcon style={{ marginRight: "10px" }} />
							Create New User
						</Button>
					</Link>
				</Box>
				<TableContainer
					className="SchrollBar"
					component={Paper}
					style={{ overflow: "auto", marginRight: "40px" }}
					sx={{ maxHeight: "800px" }}
				>
					<Table aria-label="simple table" stickyHeader>
						<TableHead>
							<StyledTableRow>
								<StyledTableCell style={{ borderBottom: "none" }} align="left">
									Housemate
								</StyledTableCell>
								{/* <StyledTableCell style={{ borderBottom: "none" }} align="right">
                  Earnings
                </StyledTableCell> */}
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									Model Status
								</StyledTableCell>
								<StyledTableCell style={{ borderBottom: "none" }} align="right">
									Current Month Earnings
								</StyledTableCell>
							</StyledTableRow>
						</TableHead>
						<TableBody>
							{models_earnings &&
								rows.map((row) => (
									<StyledTableRow
										className="transition"
										onClick={() => {
											navigate("/modelProfile/" + row.id);
										}}
										hover
										key={row.id}
										sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
									>
										<TableCell
											align="left"
											style={{
												borderBottom: "none",
												color: "#78F73B",
												display: "flex",
												alignItems: "center"
											}}
										>
											<GirlIcon
												style={{ color: "white", marginRight: "10px" }}
											/>
											{row.inHouseName ?? row.username}
										</TableCell>
										{/* <TableCell
                      align="right"
                      style={{ borderBottom: "none", color: "#FFD800" }}
                    >
                      {row.username}
                    </TableCell> */}
										<TableCell
											align="right"
											style={{
												borderBottom: "none",
												color: rowColor(row.accountStatus)
											}}
										>
											{row.accountStatus === "disable" && "disabled"}
											{row.accountStatus === "previous_resident" &&
												"previous resident"}
											{row.accountStatus !== "disable" &&
												row.accountStatus !== "previous_resident" &&
												row.accountStatus}
										</TableCell>

										<TableCell
											align="right"
											style={{ borderBottom: "none", color: "#FFD800" }}
										>
											{row.amount == null ? 0 : row.amount}
										</TableCell>
									</StyledTableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			</Stack>
			<Models_Reorder_Modal modal={modal} />
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(GirlManagement);
