import * as React from "react";
import Paper from "@mui/material/Paper";
import Button from "@mui/material/Button";
import {
	Box,
	Stack,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TableRow,
	Typography
} from "@mui/material";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import { styled } from "@mui/material/styles";
import GirlIcon from "@material-ui/icons/Face";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { connect, useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import { getUserTransactions } from "../../redux/actions/userAction";

const mapStateToProps = (state) => {
	return {
		user_transactions: state.user_transactions,
		users: state.users
	};
};

const mapDispatchToProps = (dispatch) => {
	return {
		getUserTransactions: (id, month, year) =>
			dispatch(getUserTransactions(id, month, year))
	};
};

function ModelEarnings({ getUserTransactions, user_transactions, users }) {
	const dispatch = useDispatch();
	const param = useParams();
	const navigate = useNavigate();
	React.useEffect(() => {
		getUserTransactions(param.id, 0, 0);
	}, []);

	const StyledTableRow = styled(TableRow)(({ theme }) => ({
		"&:nth-of-type(odd)": {
			backgroundColor: "rgb(0 0 0)"
		},
		"&:nth-of-type(even)": {
			backgroundColor: "#ffffff14"
		},
		// hide last border
		"&:last-child td, &:last-child th": {
			border: 0
		},
		"&.MuiTableRow-root:hover": {
			backgroundColor: "#555555"
		}
	}));

	const StyledTableCell = styled(TableCell)(({ theme }) => ({
		[`&.${tableCellClasses.head}`]: {
			backgroundColor: "#292929",
			color: theme.palette.common.white
		},
		[`&.${tableCellClasses.body}`]: {
			fontSize: 14
		}
	}));

	const data = [
		{ id: 0, name: "January" },
		{ id: 1, name: "February" },
		{ id: 2, name: "March" },
		{ id: 3, name: "April" },
		{ id: 4, name: "May" },
		{ id: 5, name: "June" },
		{ id: 6, name: "July" },
		{ id: 7, name: "August" },
		{ id: 8, name: "September" },
		{ id: 9, name: "October" },
		{ id: 10, name: "November" },
		{ id: 11, name: "December" }
	];

	const [month, setMonth] = React.useState(undefined);
	const [year, setYear] = React.useState(undefined);

	function getIndex(name) {
		return data.findIndex((obj) => obj.name == name);
	}

	// console.log(users)

	function getIndex2(id) {
		return users?.users?.findIndex((obj) => obj.id == id);
	}

	let check2 = getIndex2(param.id);

	function cameraChangeButtonForward() {
		var index = getIndex(user_transactions.current_month);
		var year = user_transactions.current_year;
		var nextid = index + 1;
		if (nextid == data.length) {
			nextid = 0;
			user_transactions.current_year += 1;
			setYear(user_transactions.current_year);
		}
		user_transactions.current_month = data[nextid].name;
		setYear(user_transactions.current_year);
		setMonth(data[nextid].name);
	}

	function cameraChangeButtonBack() {
		var index = getIndex(user_transactions.current_month);
		var year = user_transactions.current_year;
		var nextid = index - 1;
		if (nextid < 0) {
			nextid = data.length - 1;
			user_transactions.current_year -= 1;
			setYear(user_transactions.current_year);
		}
		user_transactions.current_month = data[nextid].name;
		setYear(user_transactions.current_year);
		setMonth(data[nextid].name);
	}

	React.useEffect(() => {
		if (month != undefined || year != undefined) {
			getUserTransactions(param.id, month, year);
		}
	}, [month]);

	return (
		<>
			<Stack spacing={3} sx={{ width: 600 }}>
				{/* <Box>
                    <Grid container spacing={0}>
                        <Grid item xs={4} md={2} spacing={0} className="LiveShow">
                            <Typography
                                variant="h4"
                                gutterBottom
                                component="div"
                                style={{ color: "white" }}
                            >
                                {(users && users[check2]) && users[check2].username}`s Transactions
                            </Typography>
                        </Grid>
                    </Grid>
                </Box> */}
				<Box style={{ display: "flex", justifyContent: "flex-start" }}>
					<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
						<Typography
							variant="h7"
							gutterBottom
							component="div"
							style={{ color: "white", minWidth: "150px" }}
						>
							Selected Month
						</Typography>
						<Box className="Info_box">
							<Typography
								variant="h4"
								gutterBottom
								component="div"
								style={{
									color: "#78F73B",
									minWidth: "170px",
									display: "flex",
									alignItems: "center",
									justifyContent: "center"
								}}
							>
								{Math.floor(
									user_transactions &&
										user_transactions.payments_summary &&
										user_transactions.payments_summary
								)}
							</Typography>
						</Box>
					</Box>

					<Box style={{ display: "flex", justifyContent: "flex-start" }}>
						<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
							<Button
								onClick={() => cameraChangeButtonBack()}
								startIcon={<ArrowBackIosIcon />}
								className="ModelProfilePage_arrow_back"
								variant="contained"
							>
								Previous
							</Button>
						</Box>
						<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
							<Box className="Date">
								<Typography
									variant="h6"
									component="div"
									style={{
										color: "white",
										minWidth: "170px",
										display: "flex",
										alignItems: "center",
										justifyContent: "center"
									}}
								>
									{user_transactions && user_transactions.current_month}
									&nbsp;
									{user_transactions && user_transactions.current_year}
								</Typography>
							</Box>
						</Box>
						<Box className="DashBoard_box" style={{ paddingRight: "20px" }}>
							<Button
								onClick={() => cameraChangeButtonForward()}
								endIcon={<ArrowForwardIosIcon />}
								className="ModelProfilePage_arrow_next"
								variant="contained"
							>
								Next
							</Button>
						</Box>
					</Box>
				</Box>

				<Stack spacing={3} sx={{ width: 900 }}>
					<TableContainer
						className="SchrollBar"
						component={Paper}
						style={{ overflow: "auto", marginRight: "40px" }}
						sx={{ maxHeight: "800px" }}
					>
						<Table aria-label="simple table" stickyHeader>
							<TableHead>
								<StyledTableRow>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="left"
									>
										Type of tips
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										Tokens Amount
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										Housemate
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										Time
									</StyledTableCell>
									<StyledTableCell
										style={{ borderBottom: "none" }}
										align="right"
									>
										Status
									</StyledTableCell>
								</StyledTableRow>
							</TableHead>
							<TableBody>
								{user_transactions &&
									user_transactions.payments &&
									user_transactions.payments.map((row) => (
										<StyledTableRow
											key={row.id}
											sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
										>
											<TableCell
												align="left"
												style={{
													borderBottom: "none",
													color: "#78F73B"
												}}
											>
												<GirlIcon
													style={{ color: "white", marginRight: "10px" }}
												/>
												{row.item}
											</TableCell>
											<TableCell
												align="right"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row.tokens == null ? 0 : row.tokens}
											</TableCell>
											<TableCell
												align="right"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row.inHouseName}
											</TableCell>
											<TableCell
												align="right"
												style={{ borderBottom: "none", color: "#FFD800" }}
											>
												{row.createdAt}
											</TableCell>
											<TableCell
												align="right"
												style={{
													borderBottom: "none",
													color: `${(row.status == "approved" && "green") || (row.status == "reject" && "red") || (row.status == "processing" && "#FFD800")}`
												}}
											>
												{row.status}
											</TableCell>
										</StyledTableRow>
									))}
							</TableBody>
						</Table>
					</TableContainer>
				</Stack>
			</Stack>
		</>
	);
}

export default connect(mapStateToProps, mapDispatchToProps)(ModelEarnings);
